import ContactHero from "components/common/hero/ContactHero";
import LogoStripeSlider from "components/common/LogoStripeSlider";
import React from "react";
import Helmet from "react-helmet";

import GlobalLayout from "../components/page/GlobalLayout";

export default function ContactSales() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Contact Rhombus</title>
        <meta
          name="description"
          content="The Rhombus Offices · Phone Number - 1-877-746-6797 · General and Sales -sales@rhombus.com · Support - support@rhombus.com"
        />
      </Helmet>
      <ContactHero
        pretitle="Contact Sales"
        title="Chat with Our Sales Team"
        formId="712128ab-b4c0-4f4d-9559-9cee3de642ba"
        location
        contactBlurb
      />
      <LogoStripeSlider />
    </GlobalLayout>
  );
}
